import lazySizes from 'lazysizes'
import SmoothScroll from 'smooth-scroll'
import moment from 'moment'
import DateRangePicker from 'daterangepicker'

require('./modernizrWebp.js')
require('./fresco')
require('./accessible-slick')
require('jquery-match-height')
require('velocity-animate') // Javascipt engine for snappier animations

// Breakpoint definitions aligned with the SCSS setup
// const BP_MOBILE = 600
// const BP_TABLET = 768
// const BP_DESKTOP = 1024
// const BP_WIDE = 1285
// const BP_SUPERWIDE = 1500

// Global key variables
const KEY_ESC = ['Escape', 'Esc'] // Esc for IE/Edge
// const KEY_ENTER = 'Enter'
const KEY_TAB = 'Tab'
// const KEY_SHIFT = 'Shift'

// Base elements for reference
// const HTML_ELEMENT = document.querySelector('html')
const BODY_ELEMENT = document.body
// const EVENT_ELEMENT = document.querySelector('.single-event')
// const JUMPNAV_ELEMENT = document.querySelector('#anchors')

// const MASTHEAD_ELEMENT = document.querySelector('.c-masthead, .c-home__masthead')

const HEADER_ELEMENT = document.querySelector('.c-header')
const SCROLL_OFFSET = HEADER_ELEMENT.clientHeight

// Other global variables
let isAnimationOk = window.matchMedia('(prefers-reduced-motion: no-preference)').matches
let isHomepage = BODY_ELEMENT.classList.contains('home')

// Keep the last focused element here - to improve tab order if needed
// let lastFocused = null

jQuery(function ($) {
  const bodyTag = $('body')

  /**
   * MatchHeight
   */
  $('.item').matchHeight()

  //
  // If anchor links are near the top of window (stuck), add class
  //
  const anchorTop = function () {
    var anchorDistance = $('.c-anchors').offset().top
    var $window = $(window)
    // maybe throttle scroll here
    $window.scroll(function () {
      if ($window.scrollTop() >= anchorDistance) {
        $('.c-anchors').addClass('js-anchor--top')
      } else {
        $('.c-anchors').removeClass('js-anchor--top')
      }
    })
  }

  if ($('.c-anchors').length > 0) {
    anchorTop()
  }

  //
  // Anchor mobile trigger
  //

  const anchorTrigger = $('#c-anchors__trigger')
  const anchorList = $('.c-anchors__links')

  anchorTrigger.click(function () {
    var slideDir = anchorList.is(':visible') ? 'slideUp' : 'slideDown'
    anchorList.velocity(slideDir)
    bodyTag.toggleClass('anchor-active')
  })

  /**
   * Lazy Sizes
   */
  lazySizes.init()

  //
  // Filters for Events
  //
  const filterToggle = $('#c-filter__toggle')
  const filterTag = $('.c-events__filters')

  let filtersOpen = false

  if (filterToggle.attr('aria-expanded') === 'true') {
    filtersOpen = true
  }

  const filtersToggle = function () {
    if (filtersOpen) {
      bodyTag.removeClass('filters-open')
      filterTag.removeClass('is-active')
      filterToggle.attr('aria-expanded', false)
    } else {
      bodyTag.addClass('filters-open')
      filterTag.addClass('is-active')
      filterTag.removeClass('not-active')
      filterToggle.attr('aria-expanded', true)
    }
    filtersOpen = !filtersOpen
  }

  filterToggle.on('click', function (e) {
    filtersToggle()
  })

  //
  // FAQs toggle
  //

  $('.c-faq-item__q').click(function () {
    var self = $(this)
    var slideDir = self.next().is(':visible') ? 'slideUp' : 'slideDown'
    self.next().velocity(slideDir)
    self.parent().toggleClass('faq-open')
    $('.c-faq-item__q').not(self).next().velocity('slideUp')
    $('.c-faq-item__q').not(self).parent().removeClass('faq-open')
  })

  //
  // Accordion toggle - Used in donation levels
  //

  $('.c-accordion__title-container').click(function () {
    var self = $(this)
    var slideDir = self.next().is(':visible') ? 'slideUp' : 'slideDown'
    self.next().velocity(slideDir)
    self.parent().toggleClass('state-open')
    $('.c-accordion__title-container').not(self).next().velocity('slideUp')
    $('.c-accordion__title-container').not(self).parent().removeClass('state-open')
  })

  $('html').find('.js-img-append').each(function () {
    const lower = 0
    const upper = 4
    let baseUrl = window.location.origin // doesn't work locally
    const urlString = baseUrl + '/themes/childtheme/assets/media/placeholderimages/placeholder' + (Math.floor(Math.random() * (upper - lower)) + lower) + '.jpg'
    this.src = urlString
  })

  //
  // Do search results exist? if so add class to search panel
  //

  if ($('#c-search__results').length) {
    $(bodyTag).addClass('state--has-search-results')
  }

  //
  // Smooth Scroll
  //

  const headerHeight = $('.c-header').height()

  const scroll = new SmoothScroll('a[data-scroll]', {
    offset: headerHeight + 50,
    emitEvents: true
  })
  const options = { speed: 1000, easing: 'easeOutCubic' }
  scroll.animateScroll(options)

  // Log scroll events
  var logScrollEvent = function (event) {
    // The event type
    console.log('type:', event.type)
  }

  // Listen for scroll events
  document.addEventListener('scrollStart', logScrollEvent, true)
  document.addEventListener('scrollStop', logScrollEvent, true)

  //
  // Add inactive class to unhovered list items
  //

  $('.c-footer__nav a').hover(function () {
    $('.c-footer__nav a').not(this).toggleClass('not-active')
  })

  $('.c-footer__social a').hover(function () {
    $('.c-footer__social a').not(this).toggleClass('not-active')
  })

  $('.c-anchor__link').hover(function () {
    $('.c-anchor__link').not(this).toggleClass('not-active')
  })

  $('.c-header__link').hover(function () {
    $('.c-header__link').not(this).toggleClass('not-active')
  })

  $('.page-numbers').hover(function () {
    $('.page-numbers').not(this).toggleClass('not-active')
  })

  //
  // Inline Font Awesome additons
  //

  $('.c-col-content a[href$=".pdf"]').append('<i class="fasr fa-file-pdf" aria-hidden="true"></i>')

  // Date picker
  const datePickerField = $('.c-date-select-range')

  if (datePickerField.length > 0) {
    const currentDateRangeValue = function () {
      var val = datePickerField.val()
      var dates = val.split('+')

      if (val && dates.length === 2) {
        return {
          start: moment(dates[0], 'DD-MM-YYYY'),
          end: moment(dates[1], 'DD-MM-YYYY')
        }
      }

      return {
        start: moment(),
        end: undefined
      }
    }

    const dateRangePicker = new DateRangePicker(datePickerField, {
      autoUpdateInput: false,
      locale: {
        format: 'DD MMM',
        cancelLabel: 'Clear'
      },
      linkedCalendars: true,
      alwaysShowCalendars: true,
      startDate: currentDateRangeValue().start,
      endDate: currentDateRangeValue().end,
      minDate: moment()
    })

    datePickerField.on('apply.daterangepicker', function () {
      $(this).val(dateRangePicker.startDate.format('DD-MM-YYYY') + '+' + dateRangePicker.endDate.format('DD-MM-YYYY'))
      $(this).parents('form').submit()
    })

    datePickerField.on('cancel.daterangepicker', function () {
      $(this).val('')
    })
  }

  // Submit Event Filters
  const selectFilters = $('select#genre, select#venue, select#producer, select#season, input#lensic360')

  selectFilters.on('change', function () {
    setTimeout(() => {
      $(this).parents('form').submit()
    }, 50)
  })

  //
  // Lensic 360 Carousel
  if ($('.c-lensic-360-block__inner').length) {
    $('.c-lensic-360-block__inner').slick({
      infinite: false,
      dots: true,
      speed: 300,
      slidesToScroll: 1,
      arrowsPlacement: 'afterSlides',
      appendArrows: $('.c-lensic-360-block__buttons'),
      appendDots: $('.c-lensic-360-block__dots'),
      slidesToShow: 3,
      responsive: [{
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      }]
    })
  }
})

/*
 * Debounce to limit a number of times a function is called
 * @param func function to debounce
 * @param delay time to delay the function call
 * @param immediate boolean to call the function immediately
 */
function debounce (func, delay, immediate) {
  var timeout

  return function executedFunction () {
    var context = this
    var args = arguments

    var later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }

    var callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, delay)

    if (callNow) func.apply(context, args)
  }
}

/*
* Setting up all global event handlers here
*/
(function () {
  window.addEventListener('keyup', function (e) {
    if (KEY_ESC.includes(e.key)) {
      handleEscapeKey()
    }
  })

  window.addEventListener('resize', debounce(function (e) {
    handlePageResize()
  }, 50))

  document.addEventListener('scroll', debounce(function () {
    handlePageScroll()
  }, 5))

  /*
  * Function invoked when the page is scrolled
  */
  function handlePageScroll () {
    let isStuck = window.scrollY > SCROLL_OFFSET

    BODY_ELEMENT.classList.toggle('state--menu-stuck', isStuck)
    if (!isStuck && isHomepage) {
      toggleMenuVisibility(false)
    }
  }

  /*
  * Function invoked when the page is resized
  */
  function handlePageResize () {
  }

  /*
  * Function invoked when the escape key is pressed
  */
  function handleEscapeKey () {
    toggleMenuVisibility(false)
  }
}())

/*
 * Scripts for the header and navigation
 */

let isMenuShown = false
const menuToggle = HEADER_ELEMENT.querySelector('.c-hamburger')
const megaMenu = document.querySelector('.c-nav');

(function () {
  // Toggle main menu visibility on button click
  menuToggle.addEventListener('click', function (e) {
    toggleMenuVisibility(!isMenuShown)
  })
}())

/*
* @param value boolean
* @param element HTMLelement - toggle (optional)
*/
function toggleMenuVisibility (value, element) {
  BODY_ELEMENT.classList.toggle('state--menu-open', value)
  menuToggle.setAttribute('aria-expanded', value)
  isMenuShown = value

  trapFocus([HEADER_ELEMENT, megaMenu], value ? menuToggle : null)
}

let firstElement
let lastElement

/*
* Config for the focus trap
* @param {array} parents - an array of elements to which the focus should be constrained
* @param {HTMLElement} elementToFocus - an element to focus when the trap is activated.
* Focus trap will be removed from the parent when no elementToFocus is passed
*/
function trapFocus (parents, elementToFocus) {
  // If no elementToFocus is passed, remove all event listeners and get out of here
  if (!elementToFocus) {
    // Trap off. Remove event listeners
    window.removeEventListener('keydown', handleTrapKeydown)
    return
  }

  let elements = []
  // Otherwise find all focusable elements inside the parent element passed to this function
  Array.from(parents).forEach(item => {
    elements.push(...getFocusableElements(item))
  })

  firstElement = elements[0]
  lastElement = elements[elements.length - 1]
  // Trap on
  elementToFocus.focus()
  window.addEventListener('keydown', handleTrapKeydown)
}

/*
* Get focusable elements inside a wrapper
* @param {HTMLElement} element - the wrapper element to be searched through for focusable items
* @returns {array} focusable
*/
function getFocusableElements (element) {
  let elements = element.querySelectorAll('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])')
  // Filter to remove elements that are visually hidden with css
  let focusable = Array.from(elements).filter(item => {
    return window.getComputedStyle(item).display !== 'none'
  })
  return focusable
}

/*
* Keydown event handler for the focus trap
* Checking which key was pressed, handling looping, etc.
*/
function handleTrapKeydown (e) {
  const isTabPressed = (e.key === KEY_TAB)
  if (!isTabPressed) {
    return
  }
  if (e.shiftKey) {
    if (document.activeElement === firstElement) {
      lastElement.focus()
      e.preventDefault()
    }
  } else {
    if (document.activeElement === lastElement) {
      firstElement.focus()
      e.preventDefault()
    }
  }
}

/*
* Masthead video controls
*/
const mastheadVideo = document.querySelector('.c-masthead__video')
const mastheadControl = document.querySelector('.c-masthead__video-control')
let isVideoPaused = false

if (mastheadControl) {
  if (mastheadVideo && !isAnimationOk) {
    handleMastheadControlClick()
  }

  mastheadControl.addEventListener('click', handleMastheadControlClick)
}

function handleMastheadControlClick () {
  isVideoPaused ? mastheadVideo.play() : mastheadVideo.pause()
  mastheadControl.setAttribute('aria-pressed', !isVideoPaused)
  isVideoPaused = !isVideoPaused
}
